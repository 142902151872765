:root {
  --cta_section--title_font_size: calc(100vh * 0.09);
  --cta_section--description_font_size: calc(100vh * 0.03);
}
.cta_section--main_container {
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding-left: 20px;
  margin-top: 50px;
  padding-top: 10px;
}
.cta_section--main_content_container {
  display: flex;
  height: 100%;
}
.cta_section--left_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
}
.cta_section--action_buttons_container {
  display: flex;
  align-items: center;
}
.cta_section--show_video_container {
  display: flex;
}
.cta_section--show_video_label {
  font-size: 18pt;
  color: #404040;
  margin: 0;
  text-transform: 'capitalize';
  font-family: 'Montserrat';
}
.cta_section--show_video_label_dark {
  font-size: 18pt;
  color: #FF9900;
  margin: 0;
  text-transform: 'capitalize';
  font-family: 'Montserrat';
}
.cta_section--play_video_icon{
  font-size: 64pt;
  color: #FF9900
}
.cta_section--phone_carousel_container {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
}
.cta_section--background_image {
  opacity: 0.35;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}
.cta_section--phone_frame {
  position: relative;
}
.cta_section--title {
  font-family: 'Montserrat', sans-serif;
  font-size: 48pt;
  font-weight: bold;
  color: black;
  position: relative;
}

.cta_section--title_dark {
  font-family: 'Montserrat', sans-serif;
  font-size: 48pt;
  font-weight: bold;
  color: white;
  position: relative;
}

.cta_section--description {
  font-family: 'Montserrat', sans-serif;
  font-size: var(--cta_section--description_font_size);
  font-weight: bold;
  color: rgb(0, 0, 0);
  position: relative;
}

.cta_section--description_dark {
  font-family: 'Montserrat', sans-serif;
  font-size: var(--cta_section--description_font_size);
  font-weight: bold;
  color: white;
  position: relative;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation: portrait) {
  /*PORTRAIT*/
  .cta_section--main_container {
    position: relative;
    overflow: hidden;
    height: 50%;
  }
  .cta_section--title {
    font-size: calc(100vw*(25/430));/*Referencia para width 430 es 25px*/
  }

  .cta_section--title_dark {
    font-size: calc(100vw*(25/430));/*Referencia para width 430 es 25px*/
  }
  .cta_section--description {
    font-size: calc(100vw*(15/430));/*Referencia para width 430 es 15px*/
  }

  .cta_section--description_dark {
    font-size: calc(100vw*(15/430));/*Referencia para width 430 es 15px*/
  }
  .cta_section--show_video_label {
    font-size: calc(100vw*(8/430));/*Referencia para width 430 es 8px*/
  }
  .cta_section--show_video_label_dark {
    font-size: calc(100vw*(8/430));/*Referencia para width 430 es 8px*/
  }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  .cta_section--main_container {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .cta_section--title {
    font-size: calc(100vh*(25/430));/*Referencia para width 430 es 25px*/
  }

  .cta_section--title_dark {
    font-size: calc(100vh*(25/430));/*Referencia para width 430 es 25px*/
  }
  .cta_section--description {
    font-size: calc(100vh*(15/430));/*Referencia para width 430 es 25px*/
  }

  .cta_section--description_dark {
    font-size: calc(100vh*(15/430));/*Referencia para width 430 es 25px*/
  }
  .cta_section--show_video_label {
    font-size: calc(100vh*(16/430));/*Referencia para width 430 es 16px*/
  }
  .cta_section--show_video_label_dark {
    font-size: calc(100vh*(16/430));/*Referencia para width 430 es 16px*/
  }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
@media screen and (max-width: 601px) {
  .veccontainer {
    display: grid;
    grid-template-columns: 25px 150px 150px 150px 20px;
    /* grid-template-columns: 5% 15% 15% 15% 5%; */
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    /* grid-template-rows: auto; */

    /* box-sizing: border-box; */
    /* width: 800px;
  height: 50px; */
    margin-left: 1%;
    margin-right: auto;

    border: 1px solid rgba(169, 169, 169, 0.18);
  }
}

.iconpickercontainer {
  display: grid;
  grid-template-columns: 100px 100px 100px;
}

@media screen and (min-width: 601px) {
  .veccontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-template-columns: 5% 15% 15% 15% 5%; */

    gap: 10px;
    padding: 5px;
  }
}

.veccontainer.edit {
  height: 160px;
  align-items: left;

  /* TODO check how to align selects maybe asigingn an specific column */
}
/* .editrow {
  height: 30px;
  display: unset;
  margin: '20px';
  width: fit-content;
} */

.item {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(255, 213, 70, 0.4);
  border: 2px dashed rgba(236, 198, 48, 0.5);
}

.editrow {
}

body {
  margin: 0;
  /* padding: 50px; */
  font: normal 18px/1.4 Arial, sans-serif;
}

.picker {
  width: 30;
  height: 30;
}

.swatch {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: relative;
  top: calc(100% + 2px);
  left: 0px;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.icon-picker-container {
}

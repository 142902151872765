/* Gauge.css */

.fuel_gauge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  z-index: 10000000000000;
}

.fuel_gauge {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.fuel_gauge_needle {
  position: relative;
  top: 15%;
  left: 50%;
  width: 2px;
  height: 50px;
  background-color: red;
  transform-origin: 50% 100%;
  transition: transform 0.3s;
}

.fuel_gauge__speed {
  position: absolute;
  top: 79%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
  font-weight: bold;
}

.fuel_gauge__tick {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 8px;
  background-color: black;
  transform-origin: 50% 100%;
}

/* Add more styles here if needed */

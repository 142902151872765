.landing_page--main_container{
  background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),url("/public/sat_map.png");
  background-size: cover;
}
.landing_page--main_container_dark{
  background-image: linear-gradient(rgba(9, 51, 93, 0.8), rgba(9, 51, 93, 0.8)),url("/public/sat_map.png");
  background-size: cover;
}
.landing_page--gradient {
  background: linear-gradient(#012235, var(--grey--900)) padding-box;
  border: 2px solid transparent;
  margin: 0;
  max-width: 2000px;
}

.landing_page--no_gradient {
  background: white;
  border: 2px solid transparent;
  margin: 0;
  max-width: 2000px;
}

.landing_page--hecader_cta_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}
.landing_page_app_store_badges_container{
  margin: 50px;
  flex-direction: column;
  align-items: center;
  display: flex;
}


/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
  .landing_page--hecader_cta_container {
    height: fit-content;
  }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  /*PORTRAIT*/
  .landing_page--hecader_cta_container {
    height: fit-content;
  }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
}
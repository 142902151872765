/*Las dimensiones del phone frame actual son w=215 y h=415. En base a esto las proporciones son
  1 Si quieres fijar el alto(h) y calcular el ancho(w)
    [w/h] = 215/415 = 0.5181
  2 Si quieres fijar el ancho(w) y calcular el alto(h)
    [h/w] = 415/215 = 1.9302*/
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 215px;
    height: 415px;
    
    
  }
  
  .carousel button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  .phone_carousel--phone_frame_container{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 215px;
    height: 415px;
  }
  .phone_carousel--phone_frame_image{
    width: 215px;
    height: 415px;
  }

  .phone_carousel_image{
    position: absolute;
        left: 0;
        top: 0;
    z-index: 0;
    width:100%;
    height: 100%;
    clip-path: polygon( 3.738% 14.904%,3.271% 14.904%,3.271% 18.51%,3.738% 18.51%,3.738% 21.875%,3.271% 21.875%,3.271% 28.606%,3.738% 28.606%,3.738% 30.529%,3.271% 30.529%,3.271% 37.5%,3.738% 37.5%,3.738% 91.346%,4.206% 92.788%,4.673% 93.51%,5.607% 94.231%,6.075% 94.712%,6.542% 95.433%,7.944% 95.913%,8.879% 96.394%,10.28% 97.115%,11.215% 97.356%,13.084% 97.596%,14.953% 97.837%,16.355% 98.077%,84.112% 98.077%,85.514% 97.837%,87.383% 97.596%,88.785% 97.115%,90.187% 96.635%,91.121% 96.394%,92.523% 95.673%,93.925% 94.712%,94.86% 93.75%,95.327% 92.788%,95.794% 92.308%,96.262% 90.865%,96.262% 35.096%,96.729% 35.096%,96.729% 24.038%,96.262% 24.038%,96.262% 8.173%,95.327% 6.971%,94.86% 5.769%,93.925% 4.808%,92.056% 3.846%,90.654% 3.125%,88.318% 2.644%,85.981% 2.163%,83.645% 1.923%,15.421% 1.923%,13.084% 2.163%,11.215% 2.644%,9.813% 3.125%,8.879% 3.606%,7.944% 4.087%,6.542% 4.567%,5.607% 5.288%,4.673% 6.25%,4.206% 7.212%,3.738% 8.173% );
  }

  /*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
    /*PORTRAIT*/
    .carousel {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: calc(150px*(415/215));
        
        
      }
      .phone_carousel_image{
        width: 99%;
        height: calc(150px*(415/215));
        position: absolute;
        left: 0;
        top: 0;
      }
      .phone_carousel--phone_frame_container{
        width:100%;
        height: calc(150px*(415/215));
      }
      .phone_carousel--phone_frame_image{
        width: 100%;
        height: calc(150px*(415/215));
      }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
    /*LANDSCAPE*/
    .carousel {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 50%;
        height: calc(150px*(415/215));
        
        
      }
      .phone_carousel_image{
        width: 99%;
        height: calc(150px*(415/215));
        position: absolute;
        left: 0;
        top: 0;
      }
      .phone_carousel--phone_frame_container{
        width:100%;
        height: calc(150px*(415/215));
      }
      .phone_carousel--phone_frame_image{
        width: 100%;
        height: calc(150px*(415/215));
      }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:landscape){
    /*LANDSCAPE*/
}

.feature_section--main_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.feature_section--feature_chips_container{
    display: flex;
    justify-content: space-around;
}
.feature_section--title{
    font-family: 'Montserrat', sans-serif;
  font-size: 40pt;
  font-weight: bold;
  margin: 0;
  color:black;
  text-align: center;
}
.feature_section--title_dark{
    font-family: 'Montserrat', sans-serif;
  font-size: 40pt;
  font-weight: bold;
  margin: 0;
  color: white;
  text-align: center;
}
.feature_section--description{
    font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  color:black;
  text-align: center;
}
.feature_section--description_dark{
    font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  text-align: center;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
  .feature_section--feature_chips_container{
    display: none;
}
.feature_section--title{
font-size: calc(100vw*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
}
.feature_section--title_dark{
  font-size: calc(100vw*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
  }

  .feature_section--description{
    font-size: calc(100vw*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
}
.feature_section--description_dark{
  font-size: calc(100vw*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
}
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  .feature_section--feature_chips_container{
    display: none;
}
.feature_section--title{
  font-size: calc(100vh*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
  }
  .feature_section--title_dark{
    font-size: calc(100vh*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
    }
  
    .feature_section--description{
      font-size: calc(100vh*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
  }
  .feature_section--description_dark{
    font-size: calc(100vh*(25/430));/*Para screen width 430 px el tamaño de referencia es 25px*/
  }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
}
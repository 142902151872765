.CustomSwipeableDrawer--MainContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom:30px;
    z-index: 1;
    background-color: rgb(255, 255, 255);
    min-height: 50px;
    max-height: 100dvh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.CustomSwipeableDrawer--PullerContainer{
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    max-height: 50px;
    min-height: 50px;
}
.CustomSwipeableDrawer--ContentContainer{
    display: flex;
    overflow: scroll;
}
:root{
  --grey-400:#bdbdbd;
  --grey--500:#9e9e9e;
  --grey--900:#212121;
  --base-white:#ffffff;
  --feature_chip--Title_font_size:18pt;
  --feature_chip--feature-list_font_size:16pt;
}
.feature_chip--main_container{
  display: flex;
  flex-direction: column;
  width: 250px;
  margin: 5px;
}
.feature_chip--banner_image{
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
}
.feature_chip {
background-color: #FFFFFF;
padding: 5px;
outline: 1px solid #000000;
border: 8px solid white;
border-radius: 20px;
height: 400px;
}
.feature_chip_dark {
  background-color: #FFFFFF;
  padding: 5px;
  outline: 1px solid #CCC;
  border: 8px solid rgb(3, 53, 82);
  border-radius: 20px;
  height: 400px;
  }
  .feature_chip--icon_feature_contanier{
    display: flex;
    align-items: center;
  }
.feature_chip--Title{
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}
.feature_chip--CheckCircleIcon{
  color: var(--grey--500);
  font-size: xx-small;
}

.feature_chip--feature-list{
height: 300px;
justify-content: left;
font-size: 20px;
}

.feature_chip_feature{
  font-family: 'Montserrat', sans-serif;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
  .feature_chip--Title{
    font-size: calc(100vw*(20/430));/*Para screen Height de 430px fontsize 20px es una referencia*/
  }
  .feature_chip--feature-list{
    font-size: calc(100vw*(20/430));/*Para screen Height de 430px fontsize 20px es una referencia*/
    }
    .feature_chip {
      height: calc(100vw*(360/430));/*Para screen Height de 430px , height: 350px es una referencia*/
      }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  .feature_chip--Title{
    font-size: calc(100vh*(20/430));/*Para screen Height de 430px fontsize 20px es una referencia*/
  }
  .feature_chip--feature-list{
    font-size: calc(100vh*(20/430));/*Para screen Height de 430px fontsize 20px es una referencia*/
    }
    .feature_chip {
      height: calc(100vh*(360/430));/*Para screen Height de 430px , height: 350px es una referencia*/
      }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
}

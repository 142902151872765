.UserPreferences--MainContainer{
  max-width: fit-content;
  height:88dvh;
  display: flex;
  flex-direction: column;
}
.UserPreferences--SettingsContainer{
  overflow: scroll;
  height: fit-content;
}
.UserPreferences--ActionButtonsContainer{
  display: flex;
  height: fit-content;
}

@media only screen and (max-width: 575px) and (orientation:portrait) {
  .UserPreferences--MainContainer{
    position: relative;
    top: 80px;
    padding-left: 10px;
    height:80dvh;
  }
}
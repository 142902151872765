.MiniDrawer--DrawerContainer {
}
.MiniDrawer--DrawerExpandButtonContainer{}

.MiniDrawer--AppBar{
    display: flex;
}
.MiniDrawer--ProfileNotificationsLogoContainer{
    display:flex;
    width: 100%;
    align-items: center;
}
.MiniDrawer--ProfileNotificationsContainer{
    display:flex;
    width: 100%;
    height: 56px;
    justify-content: flex-end;
    align-items: center;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation: portrait) {
  /*PORTRAIT*/
  .MiniDrawer--DrawerContainer {
    display:none
  }
  .MiniDrawer--DrawerExpandButtonContainer{
    display:none;
  }
}
@media only screen and (max-height: 575px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation: portrait) {
    .MiniDrawer--DrawerContainer {
        display:none
      }
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

.vehicleItem {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  transition: background-color 0.5s ease; /* add a smooth transition */
}

.vehicleItem:hover {
  background-color: rgb(
    235,
    233,
    233
  ); /* change the background color on hover */
}
.vehicleItem.active {
  background-color: #bfbaba; /* change the background color on hover */
}

* {
  margin: 0;
  padding: 0;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
body {
  background-color: #eee;
  font-family: 'Open Sans', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

.dark {
  background-color: #333;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #333;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(127, 148, 179);
  border-radius: 10px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

/* .side-menu {
  position: fixed;
  background: #ffffff;
  width: fit-content;
  height: 100%;
 
  padding-left: 10px;
  padding-right: 0px;
  
  transition: width 1s ease-in;
  transition: 1s;
} */

.side-menu.inactive {
  width: 70px;
  /* padding-right: 0px; */
  margin: 0px;
  padding-right: 0px;
  transition: width 1s ease-in;
}

/* .side-menu .top-section {
  position: relative;
} */

/* .side-menu .top-section .burguer_button {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
}
.side-menu .top-section .burguer_button img {
  max-width: 100%;
  max-height: 100%;
} */

.side-menu .top-section .toggle-menu-btn {
  width: 26px;
  height: 26px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  left: 180px;
  /* background-color: rgb(215, 222, 233); */
  border-radius: 15%;
  top: -35px;
}

.side-menu.inactive .top-section .toggle-menu-btn {
  left: 80px;
}

.side-menu .search-controller {
  color: #fff;
  position: relative;
}

.side-menu .search-controller .search-btn {
  width: 40px;
  height: 35px;
  position: absolute;
  background: transparent;
  border: 0;
  font-size: 20px;
  color: #666;
}

.side-menu .search-controller input[type='text'] {
  border: 0;
  outline: none;
  height: 35px;
  background: #333;
  border-radius: 5px;
  display: block;
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  color: #666;
}

.side-menu .main-menu .menu-item {
  color: rgb(189, 186, 186);
  text-decoration: none;
  font-size: 15px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  margin-top: 15px;
}

.side-menu .main-menu .menu-item.active {
  background: rgb(129, 128, 128);
  border-radius: 5px;
  color: rgb(219, 214, 214);
}

.side-menu .main-menu {
  margin: 1px 0;
  position: relative;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  right: 30px;
}
/*esto es el icono cuando esta encogido y todo el resto de la pajareria ...mind this please */
.side-menu.inactive .main-menu .menu-item {
  width: 42px;
  height: 35px;
}

.side-menu.inactive .main-menu {
  width: fit-content;
}
/* aqui termina la pajareria de esa parte  */

.side-menu .main-menu .menu-item .menu-icon {
  display: inline-block;
  color: rgb(60, 65, 114);
  /* width: 60px;
  height: 50px; */
  font-size: 20px;
  line-height: 40px;
  text-align: left;
  margin-left: 5px;
}

.side-menu .main-menu .menu-item span {
  position: absolute;
  display: inline-block;
  line-height: 40px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu.inactive .main-menu .menu-item a {
  opacity: 0;
  width: 10;
  height: 0;
  overflow: hidden;
}

.side-menu .main-menu .sub-menu {
  color: #333;
  margin-left: 20px;
  border-left: 1px solid #666;
  box-sizing: border-box;
  padding-left: 30px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
}

.side-menu .main-menu .sub-menu.active {
  max-height: 200px;
}

.side-menu .main-menu .sub-menu a {
  display: block;
  margin: 5px 0;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  color: #333;
  box-sizing: border-box;
  padding: 5px;
}

.side-menu .main-menu .sub-menu a.active {
  /* change in background, not recorded in video */
  background: #222;
  display: block;

  border-radius: 5px;
  color: #fff;
}

.side-menu-footer {
  width: 100%;
  background: #333;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}

.side-menu-footer .avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}
.side-menu-footer .avatar img {
  max-width: 100%;
}

.side-menu-footer .user-info {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
  position: absolute;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .side-menu-footer .user-info {
  opacity: 1;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu-footer .user-info h5 {
  font-size: 15px;
}

.side-menu-footer .user-info p {
  font-size: 14px;
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: rgba(225, 225, 225, 0.935);
}

.contenedor {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0px;
  /* margin-top: 10; */
  margin-left: 240px;
  transition: margin-left 0.5s ease-in;
  /* box-sizing: border-box; */
}

@media screen and (max-width: 601px) {
  .contenedor {
    top: 0;
    /* it dissapears in small screens */
    margin-left: 57px;
  }
}
@media screen and (min-width: 601px) {
  .contenedor.inactive {
    /* padding-top: 10; */
    margin-left: 60px;
    padding-right: 0;
    padding-left: 12px;
  }
}
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.dark {
  background-color: #333;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #333;
}

.header_section--main_container{
  display: flex;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: white;
  padding-top: 5px;
}

.header_section--main_container_dark{
  display: flex;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #263042;
  padding-top: 5px;
}

.header_section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
}
.header_section_dark{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
}
.header_section--menu_options_container{
  display:flex;
  flex-direction:row;
  align-items: center;
}
.header_section--menu_bar--button{
    
    text-transform:"capitalize";
    height: fit-content;
}
.header_section--menu_bar--button.dark{
  
  text-transform:"capitalize";
  height: fit-content;
}

.header_section--title--container{
  display: flex;
}
.header_section--title--text{
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-size: 30px;
  width: fit-content;
  margin: auto;/*Center the text*/
}

.header_section--title--text_dark{
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 30px;
  width: fit-content;
  margin: auto;/*Center the text*/
}


/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
  .header_section--menu_options_container{
    display:none;
  }
  .header_section--title--text{
    font-size: calc(100vw*(25/430));/*Para Screen Width 430px referencia de tamaño es 25px*/
  }
  
  .header_section--title--text_dark{
    font-size: 25px;/*Para Screen Width 430px referencia de tamaño es 25px*/
  }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  .header_section--menu_options_container{
    display:none;
  }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
}
.landing_page_login--main_container {
  display: flex;
  position: relative;
}
.landing_page_login--login_scree_main_container {
  opacity: 1; /* Start with 0 opacity */
  transition: opacity 0.5s ease-in-out,height 0.5s ease-in-out;
  display: flex;
  position: absolute;
  width: 300px;
  height: 460px;
  left: -250px;
  top: 45px;
  overflow: hidden;
}
.landing_page_login--login_scree_main_container_hidden {
  position: absolute;
  width: 300px;
  height: 0px;
  left: -250px;
  top: 45px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out,height 0.5s ease-in-out;
  overflow: hidden;
}

.geofenceItem {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  transition: background-color 0.5s ease; /* add a smooth transition */
  margin-left: -4;
  padding: 0;
  margin-top: 5;
}

.geofenceItem:hover {
  background-color: rgb(
    235,
    233,
    233
  ); /* change the background color on hover */
}
.geofenceItem.active {
  background-color: #bfbaba; /* change the background color on hover */
}

.dark_light_mode_selector--main_container{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
}
.dark_light_mode_selector--label{
    color: black;
}
.dark_light_mode_selector--label_dark{
    color: white;
}
.SessionLogin--MainContainer {
  display: flex;
  width: 100%;
  height: 100%;
}
.SessionLogin--LeftContanier {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url('/public/sat_map.png');
  background-size: cover;
  width: 40%;
  justify-content: center;
}
.SessionLogin--LeftContanierDark {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(9, 51, 93, 0.8), rgba(9, 51, 93, 0.8)),
    url('/public/sat_map.png');
  background-size: cover;
  width: 40%;
  justify-content: center;
}
.SessionLogin--RightContanier {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
}
.SessionLogin--Title {
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: black;
  position: relative;
  margin-left: 15px;
}
.SessionLogin--TitleDark {
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: white;
  position: relative;
  margin-left: 15px;
}
.SessionLogin--Subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  position: relative;
  margin-left: 15px;
}
.SessionLogin--SubtitleDark {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: white;
  position: relative;
  margin-left: 15px;
}

.SessionLogin--Logo {
  margin-left: 15px;
  margin-top: 15px;
  width: 100px;
  height: 100px;
}

.SessionLogin--LogoContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.SessionLogin--TitleSubtitleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation: portrait) {
  /*PORTRAIT*/
  .SessionLogin--MainContainer {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .SessionLogin--LeftContanier {
    width: 100%;
  }
  .SessionLogin--LeftContanierDark {
    width: 100%;
  }
  .SessionLogin--RightContanier {
    width: 100%;
  }
  .SessionLogin--Logo {
    margin-left: 15px;
    margin-top: 15px;
    width: 50px;
    height: 50px;
  }
  .SessionLogin--Title {
    font-size: 35px;
  }
  .SessionLogin--TitleDark {
    font-size: 35px;
  }
  .SessionLogin--Subtitle {
    font-size: 22px;
  }
  .SessionLogin--SubtitleDark {
    font-size: 22px;
  }
}
@media only screen and (max-height: 575px) and (orientation: landscape) {
  /*LANDSCAPE*/
  .SessionLogin--MainContainer {
    height: fit-content;
  }
  .SessionLogin--Title {
    font-size: 30px;
  }
  .SessionLogin--TitleDark {
    font-size: 30px;
  }
  .SessionLogin--Subtitle {
    font-size: 18px;
  }
  .SessionLogin--SubtitleDark {
    font-size: 18px;
  }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  /*PORTRAIT*/
  .SessionLogin--MainContainer {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .SessionLogin--LeftContanier {
    width: 100%;
  }
  .SessionLogin--LeftContanierDark {
    width: 100%;
  }
  .SessionLogin--RightContanier {
    width: 100%;
  }
  .SessionLogin--Logo {
    margin-left: 15px;
    margin-top: 15px;
    width: 50px;
    height: 50px;
  }
  .SessionLogin--Title {
    font-size: 35px;
  }
  .SessionLogin--TitleDark {
    font-size: 35px;
  }
  .SessionLogin--Subtitle {
    font-size: 22px;
  }
  .SessionLogin--SubtitleDark {
    font-size: 22px;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation: portrait) {
  /*PORTRAIT*/
  .SessionLogin--MainContainer {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .SessionLogin--LeftContanier {
    width: 100%;
  }
  .SessionLogin--LeftContanierDark {
    width: 100%;
  }
  .SessionLogin--RightContanier {
    width: 100%;
  }
  .SessionLogin--Logo {
    margin-left: 15px;
    margin-top: 15px;
    width: 50px;
    height: 50px;
  }
  .SessionLogin--Title {
    font-size: 35px;
  }
  .SessionLogin--TitleDark {
    font-size: 35px;
  }
  .SessionLogin--Subtitle {
    font-size: 22px;
  }
  .SessionLogin--SubtitleDark {
    font-size: 22px;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

.landing_page_mobile_menu--main_container{
  display: flex;
  align-items: center;
}

/* Add this to your stylesheet */
.landing_page_mobile_menu--dropdown {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.landing_page_mobile_menu--dropbtn {
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.landing_page_mobile_menu--dropdown-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #f9f9f9;
  width: calc(100vw*(190/430));/*Para Screen Width 430px la referencia es 180px*/
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  right: 0;
}

.landing_page_mobile_menu--dropdown-content div {
  padding: 12px 16px;
  cursor: pointer;
}

.landing_page_mobile_menu--dropdown:hover .landing_page_mobile_menu--dropdown-content {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  position: absolute;
}

.landing_page_mobile_menu--option_list_container{

}
.landing_page_mobile_menu--item{
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(100vw*(15/430));/*Para Screen Width 430px fontsize 15px*/

}
.landing_page_mobile_menu--sub_item{
  font-family: 'Montserrat', sans-serif;
  font-size: calc(100vw*(15/430));/*Para Screen Width 430px fontsize 15px*/
}
.landing_page_mobile_menu--dropdown-content {
  width: calc(100vh*(190/430));/*Para Screen Width 430px la referencia es 180px*/
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  .landing_page_mobile_menu--item{
    font-size: calc(100vh*(15/430));/*Para Screen Width 430px fontsize 15px*/
  
  }
  .landing_page_mobile_menu--sub_item{
    font-size: calc(100vh*(15/430));/*Para Screen Width 430px fontsize 15px*/
  }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
  .landing_page_mobile_menu--main_container{
    display: none;
  }
}
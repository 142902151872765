:root {
    --dvh: 100dvh;
  }
.MapController--MainContainer{
    display: flex;
    background-color: white;
}
.MapController--MainContainerColapsed{
    display: flex;
    background-color: white;
}
.MapController--HeaderContainer{
    display: none;
}
.MapController--MapActionButtonsContainer{
    display: flex;    
    opacity: 1;
    transition: opacity 1s ease-in-out;
}
.MapController--MapActionButtonsContainer--hidden{
    display: flex;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
    /*PORTRAIT*/
    .MapController--MainContainer{
        display: flex;
        height: 80dvh;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 2000;
        bottom: 5px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .MapController--MainContainerColapsed{
        display: flex;
        height: 250px;
        width: 100%;
        overflow: scroll;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: calc(var(--dvh) - 250px);
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
    }
    .MapController--HeaderContainer{
        display: flex;
        cursor: pointer;
        justify-content: center;
    }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
    /*LANDSCAPE*/
    .MapController--MainContainer{
        display: flex;
        height: 84%;
        width: 85%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 500;
        bottom: 0px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .MapController--MainContainerColapsed{
        display: flex;
        height: 20px;
        width: 85%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 500;
        bottom: 0px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
    }
    .MapController--HeaderContainer{
        display: flex;
        cursor: pointer;
        justify-content: center;
    }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
    /*PORTRAIT*/
    /*PORTRAIT*/
    .MapController--MainContainer{
        display: flex;
        height: 90%;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 500;
        bottom: 5px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .MapController--MainContainerColapsed{
        display: flex;
        height: 210px;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 500;
        bottom: 5px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
    }
    .MapController--HeaderContainer{
        display: flex;
        cursor: pointer;
        justify-content: center;
    }
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
    /*PORTRAIT*/
    .MapController--MainContainer{
        display: flex;
        height: 90%;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 500;
        bottom: 5px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .MapController--MainContainerColapsed{
        display: flex;
        height: 210px;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        z-index: 500;
        bottom: 5px;
        transition: height 0.5s ease-in-out;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
    }
    .MapController--HeaderContainer{
        display: flex;
        cursor: pointer;
        justify-content: center;
    }
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
    /*LANDSCAPE*/
}
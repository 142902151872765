/* Gauge.css */

.gauge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  opacity: 1;
}

.gauge {
  position: relative;

  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.gauge__needle {
  position: relative;
  top: 15%;
  left: 50%;
  width: 2px;
  height: 80px;
  background-color: red;
  transform-origin: 50% 100%;
  transition: transform 0.3s;
}

.gauge__speed {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.gauge__tick {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 8px;
  background-color: rgb(255, 255, 255);
  transform-origin: 50% 100%;
  color: white;
}

.odometer {
  font-family: 'SevenSegment';
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}
/* Add more styles here if needed */
